import React from "react"

class FourOFour extends React.Component {
    render() {
        return (
            <div>
                <h1>4o4 Page</h1>
            </div>
        )
    }
}

export default FourOFour
